import styled from "@emotion/styled";
import { graphql, PageProps } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import { Flex } from "reflexbox/styled-components";
import AppContainer from "../components/container";
import { rgba } from "polished";
import { Helmet } from "react-helmet";
import AppMarkdown from "../components/markdown";

const Header = styled(Flex)(({ theme }) => ({
  minHeight: 250,
  maxHeight: 500,
  width: "100%",
  background: `linear-gradient(180deg, ${theme.colors.primary.light} 0%, ${theme.colors.primary.main} 100%)`,
  padding: `${theme.spacing(2)}px ${theme.pagePadMobile}px`,
  boxSizing: "border-box",
  "&&": {
    marginBottom: theme.spacing(4),
  },
  [theme.mq.desktop]: {
    padding: `0 ${theme.pagePad}px`,
    height: "30vh",
  },
}));

const HeaderContainer = styled(AppContainer)(({ theme }) => ({
  alignItems: "center",
  [theme.mq.desktop]: {
    alignItems: "flex-start",
  },
}));

const TitleWrap = styled(Flex)(({ theme }) => ({
  borderBottomColor: rgba(theme.colors.primary.contrastText, 0.8),
  borderBottomStyle: "solid",
  borderBottomWidth: 4,
  paddingBottom: theme.spacing(1) / 2,
  flexDirection: "column",
  marginBottom: theme.spacing(1),
  [theme.mq.desktop]: {
    flexDirection: "row",
    marginBottom: 0,
  },
}));

const Title = styled.h1(({ theme }) => ({
  color: theme.colors.secondary.contrastText,
  fontWeight: 500,
  fontSize: 40,
  margin: 0,
  [theme.mq.desktop]: {
    fontSize: 65,
  },
}));

const Subtitle = styled.p(({ theme }) => ({
  color: theme.colors.secondary.contrastText,
  fontSize: 19,
  width: "80%",
  textAlign: "center",
  [theme.mq.desktop]: {
    textAlign: "left",
  },
}));

const Icon = styled.img(({ theme }) => ({
  height: 50,
  [theme.mq.desktop]: {
    marginRight: theme.spacing(2),
  },
}));

const Content = styled.div(({ theme }) => ({
  padding: `0 ${theme.pagePadMobile}px`,
  "&&": {
    marginBottom: theme.spacing(4),
  },
  [theme.mq.desktop]: {
    padding: 0,
  },
}));

const AppSpecialityTemplate = ({
  data,
}: PageProps<GatsbyTypes.SpecialityPageQuery>) => {
  return (
    <>
      <Helmet
        title={`RWS | ${data?.markdownRemark?.frontmatter?.title}`}
      ></Helmet>
      <Header
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
      >
        <HeaderContainer flexDirection="column" breakpoint="desktop">
          <TitleWrap alignItems="center">
            <Icon src={data.markdownRemark?.frontmatter?.icon} />
            <Title>{data?.markdownRemark?.frontmatter?.title}</Title>
          </TitleWrap>
          <Subtitle>{data.markdownRemark?.frontmatter?.subtitle}</Subtitle>
        </HeaderContainer>
      </Header>
      <AppContainer breakpoint="desktop">
        <Content>
          <AppMarkdown content={data.markdownRemark?.frontmatter?.text ?? ""} />
        </Content>
      </AppContainer>
    </>
  );
};

export default AppSpecialityTemplate;

export const query = graphql`
  query SpecialityPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        icon
        subtitle
        text
      }
    }
  }
`;
